var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CommunityInfoConfigForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基本信息" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "第三方平台名称",
                        rules: [
                          {
                            required: true,
                            message: "请选择第三方平台名称",
                            trigger: "change",
                          },
                        ],
                        prop: "appType",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.platformOps, width: _vm.width },
                        on: { change: _vm.onPlatformChange },
                        model: {
                          value: _vm.form.appType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "appType", $$v)
                          },
                          expression: "form.appType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "产品类型",
                        rules: [
                          {
                            required: true,
                            message: "请选择产品类型",
                            trigger: "change",
                          },
                        ],
                        prop: "appId",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.productTypeOps,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.appId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "appId", $$v)
                          },
                          expression: "form.appId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属项目",
                        rules: [
                          {
                            required: true,
                            message: "请选择所属项目",
                            trigger: "change",
                          },
                        ],
                        prop: "communityIds",
                      },
                    },
                    [
                      _c("chosenListPanel", {
                        attrs: { list: _vm.form.communityIds },
                        on: {
                          "update:list": function ($event) {
                            return _vm.$set(_vm.form, "communityIds", $event)
                          },
                          select: _vm.toSelectCommunity,
                          change: _vm.onChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("multi-select", {
                attrs: {
                  title: "项目列表",
                  isShow: _vm.selectCommunityShow,
                  searchUrl: _vm.getCommunityListURL,
                  headers: _vm.communityTableHeader,
                  searchParams: _vm.searchParams,
                  responseParams: _vm.responseParams,
                  responseKey: _vm.responseKey,
                  backFill: _vm.form.communityIds,
                  handleData: _vm.handleData,
                },
                on: {
                  "update:isShow": function ($event) {
                    _vm.selectCommunityShow = $event
                  },
                  "update:is-show": function ($event) {
                    _vm.selectCommunityShow = $event
                  },
                  "update:backFill": function ($event) {
                    return _vm.$set(_vm.form, "communityIds", $event)
                  },
                  "update:back-fill": function ($event) {
                    return _vm.$set(_vm.form, "communityIds", $event)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "searchSlot",
                    fn: function () {
                      return [
                        _c("v-input", {
                          attrs: { label: "项目名称" },
                          model: {
                            value: _vm.searchParams.communityName,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "communityName", $$v)
                            },
                            expression: "searchParams.communityName",
                          },
                        }),
                        _c("v-select", {
                          attrs: {
                            label: "区域类型",
                            options: _vm.searchRegionTypeOps,
                          },
                          model: {
                            value: _vm.searchParams.searchRegionType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.searchParams,
                                "searchRegionType",
                                $$v
                              )
                            },
                            expression: "searchParams.searchRegionType",
                          },
                        }),
                        _c(
                          "v-select2",
                          _vm._b(
                            {
                              attrs: {
                                label: "所属区域",
                                subjoin: {
                                  regionType: _vm.searchParams.searchRegionType,
                                },
                              },
                              model: {
                                value: _vm.searchParams.regionId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchParams, "regionId", $$v)
                                },
                                expression: "searchParams.regionId",
                              },
                            },
                            "v-select2",
                            _vm.allRegionParams,
                            false
                          )
                        ),
                        _c("v-input", {
                          attrs: { label: "所在省" },
                          model: {
                            value: _vm.searchParams.province,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "province", $$v)
                            },
                            expression: "searchParams.province",
                          },
                        }),
                        _c("v-input", {
                          attrs: { label: "所在城市" },
                          model: {
                            value: _vm.searchParams.city,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "city", $$v)
                            },
                            expression: "searchParams.city",
                          },
                        }),
                        _c("v-input", {
                          attrs: { label: "所在区" },
                          model: {
                            value: _vm.searchParams.area,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "area", $$v)
                            },
                            expression: "searchParams.area",
                          },
                        }),
                        _c("v-select", {
                          attrs: {
                            label: "项目阶段状态",
                            options: _vm.communityStageOps,
                          },
                          model: {
                            value: _vm.searchParams.communityStage,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "communityStage", $$v)
                            },
                            expression: "searchParams.communityStage",
                          },
                        }),
                        _c("v-select", {
                          attrs: {
                            label: "项目类型",
                            options: _vm.communityTypeOps,
                          },
                          model: {
                            value: _vm.searchParams.communityType,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "communityType", $$v)
                            },
                            expression: "searchParams.communityType",
                          },
                        }),
                        _c("v-select", {
                          attrs: {
                            label: "项目状态",
                            options: _vm.communityStatusOps,
                          },
                          model: {
                            value: _vm.searchParams.communityStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "communityStatus", $$v)
                            },
                            expression: "searchParams.communityStatus",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }