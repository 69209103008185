<!--
 * @Description: 项目信息配置
 * @Author: 蒙川
 * @Date: 2019-08-27 10:43:44
 * @LastEditors: 小广
 * @LastEditTime: 2020-05-26 18:49:30
 -->
<template>
  <div class="CommunityInfoConfigForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
    >
      <col2-detail>
        <col2-block title="基本信息">
          <el-form-item label="第三方平台名称" :rules="[{ required: true, message: '请选择第三方平台名称', trigger: 'change' }]" prop="appType">
            <v-select v-model="form.appType" :options="platformOps" :width="width" @change="onPlatformChange" />
          </el-form-item>
          <el-form-item label="产品类型" :rules="[{ required: true, message: '请选择产品类型', trigger: 'change' }]" prop="appId">
            <v-select v-model="form.appId" :options="productTypeOps" :width="width" />
          </el-form-item>
          <el-form-item label="所属项目" :rules="[{ required: true, message: '请选择所属项目', trigger: 'change' }]" prop="communityIds">
            <chosenListPanel :list.sync="form.communityIds" @select="toSelectCommunity" @change="onChange"></chosenListPanel>
          </el-form-item>
        </col2-block>
        <multi-select
          title="项目列表"
          :isShow.sync="selectCommunityShow"
          :searchUrl="getCommunityListURL"
          :headers="communityTableHeader"
          :searchParams="searchParams"
          :responseParams="responseParams"
          :responseKey="responseKey"
          :backFill.sync="form.communityIds"
          :handleData="handleData"
        >
          <template #searchSlot>
            <v-input
              label="项目名称"
              v-model="searchParams.communityName"
            ></v-input>
            <v-select
              label="区域类型"
              v-model="searchParams.searchRegionType"
              :options="searchRegionTypeOps"
            ></v-select>
            <v-select2
              label="所属区域"
              v-model="searchParams.regionId"
              v-bind="allRegionParams"
              :subjoin="{regionType : searchParams.searchRegionType}"
            ></v-select2>
            <v-input label="所在省" v-model="searchParams.province"></v-input>
            <v-input label="所在城市" v-model="searchParams.city"></v-input>
            <v-input label="所在区" v-model="searchParams.area"></v-input>
            <!-- <v-select2
              label="运营项目组"
              v-model="searchParams.operateRegionId"
              v-bind="operateRegionParams"
            ></v-select2> -->
            <v-select
              label="项目阶段状态"
              v-model="searchParams.communityStage"
              :options="communityStageOps"
            ></v-select>
            <v-select
              label="项目类型"
              v-model="searchParams.communityType"
              :options="communityTypeOps"
            ></v-select>
            <v-select
              label="项目状态"
              v-model="searchParams.communityStatus"
              :options="communityStatusOps"
            ></v-select>
          </template>
        </multi-select>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { createURL, queryProductTypeURL, getCommunityListURL, getOperateRegionsURL, getProjectTypesURL, getRoomTypesURL } from './api'
import { setStatusOps, setPlatformOps, communityStatusOps, communityStatusMap, searchRegionTypeOps } from './map'
import { allRegionParams } from 'common/select2Params'
import { Col2Detail, Col2Block, MultiSelect, ChosenListPanel } from 'components/bussiness'

export default {
  name: 'CommunityInfoConfigForm',
  components: {
    Col2Detail,
    Col2Block,
    MultiSelect,
    ChosenListPanel
  },
  data () {
    return {
      width: 182,
      statusOps: setStatusOps(),
      platformOps: setPlatformOps(),
      communityStatusOps: communityStatusOps(1),
      form: {
        communityIds: [],
        appId: undefined
      },
      submitConfig: {
        submitUrl: ''
      },
      productTypeOps: [],
      productTypes: [],
      searchRegionTypeOps: searchRegionTypeOps(1),
      getCommunityListURL,
      communityTableHeader: [
        {
          prop: 'communityName',
          label: '项目名称'
        },
        {
          prop: 'communityStatusText',
          label: '项目状态'
        },
        {
          prop: 'communityStage',
          label: '项目阶段'
        },
        {
          prop: 'provinceName',
          label: '所在省'
        },
        {
          prop: 'cityName',
          label: '所在市'
        },
        {
          prop: 'countryName',
          label: '所在区'
        },
        {
          prop: 'communityPhone',
          label: '项目电话'
        }
      ],
      searchParams: {
        communityName: '',
        searchRegionType: undefined,
        regionId: '',
        province: '',
        city: '',
        area: '',
        operateRegionId: '',
        communityStage: undefined,
        communityType: undefined,
        communityStatus: 0
      },
      responseParams: {
        id: 'id',
        name: 'communityName'
      },
      responseKey: {
        id: 'id',
        name: 'text'
      },
      communityStageOps: [],
      communityTypeOps: [],
      allRegionParams,
      operateRegionParams: {
        searchUrl: getOperateRegionsURL,
        request: {
          text: 'regionName',
          value: 'operateRegionId'
        }
      },
      selectCommunityShow: false
    }
  },
  mounted () {
    this.$setBreadcrumb('新增')
    this.submitConfig.submitUrl = createURL
    this.getProductType()
    this.getProjectTypes()
    this.getRoomTypes()
  },
  methods: {
    submitBefore (data) {
      let obj = this.productTypes.find(item => this.form.appId === item.appId)
      data.appName = obj.name
      data.communityIds = this.form.communityIds.map(item => item.id)

      let communityNames = this.form.communityIds.map(item => item.text)
      let dataObject = `${data.appName}-${communityNames}`
      this.$set(data, 'dataObject', dataObject)

      return true
    },
    async getProductType () {
      let { data } = await this.$axios.get(queryProductTypeURL)
      this.productTypes = data
    },
    onPlatformChange (val) {
      this.productTypeOps = this.productTypes.filter(item => item.appType === val).map(item => ({ text: item.name, value: item.appId }))
      if (this.productTypeOps.length > 0) {
        this.form.appId = this.productTypeOps[0].value
      }
    },

    onChange (list) {
      this.$emit('update:list', list)
    },
    toSelectCommunity () {
      this.selectCommunityShow = true
    },
    handleData (data) {
      data.communityStatusText = communityStatusMap[data.communityStatus]
    },
    async getProjectTypes () {
      let res = await this.$axios.get(getProjectTypesURL)
      if (res.status === '100') {
        let { data } = res
        let ops = data.map(item => ({ text: item.value, value: item.id }))
        this.communityStageOps = [
          {
            text: '全部',
            value: undefined
          },
          ...ops
        ]
      }
    },
    async getRoomTypes () {
      let res = await this.$axios.get(getRoomTypesURL)
      if (res.status === '100') {
        let { data } = res
        let ops = data.map(item => ({ text: item.value, value: item.id }))
        this.communityTypeOps = [
          {
            text: '全部',
            value: undefined
          },
          ...ops
        ]
      }
    }
  }
}
</script>
