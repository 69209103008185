import { mapHelper } from 'common/utils'

// 状态
const status = [
  {
    text: '开启',
    value: 1
  },
  {
    text: '关闭',
    value: 0
  }
]

const {
  map: statusMap,
  setOps: setStatusOps
} = mapHelper.setMap(status)

// 第三方名称
const platform = [
  {
    text: '支付宝',
    value: 1
  },
  {
    text: '微信',
    value: 2
  }
]

const {
  map: platformMap,
  setOps: setPlatformOps
} = mapHelper.setMap(platform)

const communityStatus = [
  {
    text: '正常',
    value: 0
  }, {
    text: '关闭',
    value: 1
  }
]
const {
  map: communityStatusMap,
  setOps: communityStatusOps
} = mapHelper.setMap(communityStatus)

const searchRegionType = [
  {
    text: '公司',
    value: 0
  },
  // {
  //   text: '园区公司分公司',
  //   value: 1
  // },
  {
    text: '管理项目组',
    value: 2
  }
]

const {
  map: searchRegionTypeMap,
  setOps: searchRegionTypeOps
} = mapHelper.setMap(searchRegionType)

export {
  statusMap,
  setStatusOps,
  platformMap,
  setPlatformOps,
  communityStatusMap,
  communityStatusOps,
  searchRegionTypeMap,
  searchRegionTypeOps
}
