// 获取列表
const getListURL = `${API_CONFIG.shopBaseURL}community/microapp/page/list`
// 导出列表
const exportListURL = `${API_CONFIG.shopBaseURL}community/microapp/list/export`

// 新增
const createURL = `${API_CONFIG.shopBaseURL}community/microapp/add/info`
// 状态更改
const changeStatusURL = `${API_CONFIG.shopBaseURL}community/microapp/status/info`

// 获取产品类型
const queryProductTypeURL = `${API_CONFIG.shopBaseURL}community/microapp/list/microAppInfo`
// 获取项目列表
const getCommunityListURL = `${API_CONFIG.controlBaseURL}community/getCommunityList`
// 获取运营项目组
const getOperateRegionsURL = `${API_CONFIG.baseURL}serverCodewordAction!getOperateRegions.action`
// 获取阶段状态
const getProjectTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getProjectTypes.action`
// 项目类型
const getRoomTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getRoomTypes.action`

export {
  getListURL,
  exportListURL,
  createURL,
  queryProductTypeURL,
  getCommunityListURL,
  getOperateRegionsURL,
  getProjectTypesURL,
  getRoomTypesURL,
  changeStatusURL
}
